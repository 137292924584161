$(document).ready(function() {
  // Show menu
  $('.js-show-menu').click(function(e) {
    e.preventDefault();

    $('body').addClass('is-open');
  });

  // Hide menu
  $('.js-hide-menu').click(function(e) {
    e.preventDefault();

    $('body').removeClass('is-open');
  });

  // Can also be used with $(document).ready()
  $(window).load(function() {
    $('.flexslider').flexslider({
      directionNav: false
    });
  });
});
